<script setup lang="ts">
import { defineProps, withDefaults, computed, ref } from 'vue';
import { AgCharts } from 'ag-charts-vue3';
import {
  AgBarSeriesTooltipRendererParams,
  AgStandaloneChartOptions,
  AgAreaSeriesOptions,
  AgBarSeriesOptions,
  AgStandaloneSeriesOptions,
} from 'ag-charts-community';
import { TimeFrame } from '@msl/analytics-page-gateway-sdk';
import { AggregatedData } from '../utils/aggregate-data';
import { ViewBy } from '../types/analytics';

const props = withDefaults(
  defineProps<{
    data: AggregatedData[];
    timeFrame: TimeFrame;
    viewBy: ViewBy;
  }>(),
  {
    timeFrame: TimeFrame.TWO_WEEKS,
    viewBy: ViewBy.Days,
  }
);

const chartContainer = ref<HTMLElement | null>(null);

const tooltipRenderer = (params: AgBarSeriesTooltipRendererParams): string => {
  const viewByLabel = () => {
    switch (props.viewBy) {
      case 'days':
        return 'Daily';
      case 'weeks':
        return 'Weekly';
      case 'months':
        return 'Monthly';
    }
  };
  return `<div style="display: flex;flex-direction: column; justify-content: center;font-size: 10px; font-family: Mulish"><div style="background-color:#E0E0E0; padding: .5rem ">${params.datum.date}</div><div style=" padding: .5rem">${viewByLabel()} views:<b>&nbsp;${params.datum.views}</b></div></div>`;
};

const seriesConfig = {
  area: {
    type: 'area',
    xKey: 'date',
    yKey: 'views',
    stroke: '#1E90FA',
    strokeWidth: 3,
    fillOpacity: 0.15,
    interpolation: {
      type: 'smooth',
      tension: 1,
    },
    label: {
      enabled: true,
      fontFamily: 'Mulish',
      fontSize: 10,
    },
    marker: { enabled: true },
    tooltip: { showArrow: true, renderer: tooltipRenderer },
  } as AgAreaSeriesOptions,

  bar: {
    type: 'bar',
    xKey: 'date',
    yKey: 'views',
    stroke: '#1E90FA',
    strokeWidth: 3,
    fill: '#1E90FA',
    fillOpacity: 1,
    cornerRadius: 10,
    tooltip: { showArrow: true, renderer: tooltipRenderer },
    highlightStyle: {
      item: {
        fill: 'white',
        fillOpacity: 0.3,
        strokeWidth: 0,
      },
    },
  } as AgBarSeriesOptions,
};

// Compute the series based on props.viewBy.
// If viewBy is 'months', use the bar series; otherwise use the area series.
const series = computed<AgStandaloneSeriesOptions[]>(
  () =>
    (props.viewBy === 'months'
      ? [seriesConfig.bar]
      : [seriesConfig.area]) as unknown as AgStandaloneSeriesOptions[]
);

// Build the chart options.
const chartOptions = computed<AgStandaloneChartOptions>(() => ({
  container: document.getElementById('chartContainer'),
  data: props.data,
  series: series.value,
  height: 380,
  padding: { top: 50, bottom: 0, left: 10, right: 20 },
  axes: [
    {
      type: 'category',
      position: 'bottom',
      label: {
        fontSize: 10,
        fontFamily: 'Mulish',
      },
      paddingOuter: 0.1,
      interval: { minSpacing: 70 },
    },
    {
      type: 'number',
      position: 'left',
      label: {
        fontSize: 10,
        fontFamily: 'Mulish',
      },
    },
  ],
}));
</script>

<template>
  <div id="chartContainer" ref="chartContainer">
    <AgCharts :options="chartOptions" />
  </div>
</template>
