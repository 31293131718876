<script setup lang="ts">
import { WsSelect, WsSelectOption } from '@mfl/common-components';
import { TimeFrame } from '@msl/analytics-page-gateway-sdk';
import { strings } from '../analytics-page-strings';
import AgChart from './chart-view.vue';
import { AggregatedData } from '../utils/aggregate-data';
import { ViewBy } from '../types/analytics';

defineProps<{
  isLoading: boolean;
  hasData: boolean;
  selectedViewBy: ViewBy;
  selectedTimeFrame: TimeFrame;
  chartData: AggregatedData[];
}>();

const emit = defineEmits<{
  (e: 'update:viewBy', value: ViewBy): void;
}>();

const VIEW_BY_LABELS: Record<string, string> = {
  days: strings.viewBySelectLabelDays,
  weeks: strings.viewBySelectLabelWeeks,
  months: strings.viewBySelectLabelMonths,
};

function getViewByLabel(viewByLabel: string): string {
  return VIEW_BY_LABELS[viewByLabel] || VIEW_BY_LABELS.days;
}

const viewBySelectOptions = [
  {
    label: strings.viewBySelectLabelDays,
    value: 'days',
    aid: 'VIEW_BY_SELECT_DAYS',
  },
  {
    label: strings.viewBySelectLabelWeeks,
    value: 'weeks',
    aid: 'VIEW_BY_SELECT_WEEKS',
  },
  {
    label: strings.viewBySelectLabelMonths,
    value: 'months',
    aid: 'VIEW_BY_SELECT_MONTHS',
  },
];
</script>

<template>
  <div>
    <div class="view-by-select">
      <div>
        <span class="label">{{ strings.viewBySelectLabel }}</span>
      </div>
      <WsSelect
        :model-value="selectedViewBy"
        class="time-frame-select"
        label-position="outside"
        aid="ANALYTICS_PAGE_SELECT_VIEW_BY"
        variant="text"
        underline
        size="lg"
        :option-key="(v: string) => v"
        :option-label="(v: string) => getViewByLabel(v)"
        @update:model-value="
          (val: ViewBy | ViewBy[] | null) => {
            // WsSelect will always emit a single ViewBy value in this context
            emit('update:viewBy', val as ViewBy);
          }
        "
      >
        <WsSelectOption
          v-for="o in viewBySelectOptions"
          :key="o.value"
          :aid="o.aid"
          :value="o.value"
          :label="o.label"
        >
          {{ o.label }}
        </WsSelectOption>
      </WsSelect>
    </div>

    <!-- Loading state -->
    <div v-if="isLoading" class="loader">
      <div>
        <span id="frame-loader" aria-hidden="true"></span>
      </div>
    </div>

    <!-- Empty state -->
    <div v-else-if="!hasData" class="empty-state">
      <img
        src="../assets/analytics_empty_view.png"
        style="width: 280px"
        :alt="strings.altAttributes.emptyStateImage"
      />
      <span class="text-xl font-bold">{{ strings.emptyStateMessage }}</span>
    </div>

    <!-- Chart display -->
    <div v-else id="chartContainer" class="chart-container">
      <AgChart
        :data="chartData"
        :time-frame="selectedTimeFrame"
        :view-by="selectedViewBy"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.loader {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.empty-state {
  height: 360px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.view-by-select {
  display: flex;
  padding-top: 4px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;

  .label {
    font-weight: 600;
  }
}
</style>
