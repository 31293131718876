import { useQuasarPlugin } from '@mfl/common-components';
import { bootstrapMicrofrontend } from '@mfl/vue-common';

import mfe from './analytics-page-mfe.vue';

const { bootstrap, mount, unmount } = bootstrapMicrofrontend({
  name: 'analytics-page',
  mainComponent: mfe,
  targetElement: '#content',
  includeCss: true,
  handleVueApp: (app) => {
    useQuasarPlugin(app);
  },
});

export { bootstrap, mount, unmount };
