import { BQPoint } from '@msl/analytics-page-gateway-sdk';
import { ViewBy } from '../types/analytics';
import { monthNames, dayNames } from './consts';

export type AggregatedData = {
  date: string;
  views: number;
  fullDate: string;
};

const sortDataByDate = (data: BQPoint[]): BQPoint[] => {
  return [...data].sort((a, b) => {
    if (a.year !== b.year) return Number(a.year) - Number(b.year);
    if (a.month !== b.month) return Number(a.month) - Number(b.month);
    return Number(a.day) - Number(b.day);
  });
};

const formatDateString = (
  dateObj: Date,
  dayNum: string,
  year: string,
  viewBy: ViewBy
): string => {
  const monAbbr: string = monthNames[dateObj.getMonth()];
  const dayAbbr: string = dayNames[dateObj.getDay()];
  return `${viewBy === 'days' ? dayAbbr + ', ' : ''}${monAbbr} ${dayNum}, ${year}`;
};

const aggregateByDays = (
  sortedData: BQPoint[],
  viewBy: ViewBy
): AggregatedData[] => {
  return sortedData.map((item: BQPoint): AggregatedData => {
    const dateObj = new Date(
      Number(item.year),
      Number(item.month) - 1,
      Number(item.day)
    );
    const dayNum: string = ('0' + item.day).slice(-2);
    const monAbbr: string = monthNames[dateObj.getMonth()];
    const date: string = `${dayNum}-${monAbbr}`;
    const fullDate: string = formatDateString(
      dateObj,
      dayNum,
      String(item.year),
      viewBy
    );

    return {
      date,
      views: item.cnt ? item.cnt : 0,
      fullDate,
    };
  });
};

const aggregateByWeeks = (
  sortedData: BQPoint[],
  viewBy: ViewBy
): AggregatedData[] => {
  const weeks: AggregatedData[] = [];
  const reversedData = [...sortedData].reverse();

  for (let i = 0; i < reversedData.length; i += 7) {
    const weekGroup: BQPoint[] = reversedData.slice(i, i + 7);
    const firstItem: BQPoint = weekGroup[0];
    const dateObj = new Date(
      Number(firstItem.year),
      Number(firstItem.month) - 1,
      firstItem.day
    );
    const dayNum: string = ('0' + firstItem.day).slice(-2);
    const monAbbr: string = monthNames[dateObj.getMonth()];
    const date: string = `${dayNum}-${monAbbr}`;
    const fullDate: string = formatDateString(
      dateObj,
      dayNum,
      String(firstItem.year),
      viewBy
    );

    const sumViews: number = weekGroup.reduce(
      (sum, cur) => sum + Number(cur.cnt),
      0
    );
    weeks.push({
      date,
      views: sumViews,
      fullDate,
    });
  }
  return weeks.reverse();
};

const aggregateByMonths = (sortedData: BQPoint[]): AggregatedData[] => {
  const groups: Record<string, { month: number; year: number; cnt: number }> =
    {};

  sortedData.forEach((item) => {
    const key = `${item.year}-${item.month}`;
    if (!groups[key]) {
      groups[key] = {
        month: Number(item.month),
        year: Number(item.year),
        cnt: 0,
      };
    }
    groups[key].cnt += Number(item.cnt);
  });

  return Object.values(groups).map((group) => {
    const monAbbr: string = monthNames[group.month - 1];
    const date: string = `${monAbbr} ${group.year}`;
    return {
      date,
      views: group.cnt,
      fullDate: date,
    };
  });
};

/**
 * Aggregates the received data from the backend (BQPoint[]) by the viewBy parameter.
 * @param data - Array of BQPoint objects from the backend.
 * @param viewBy - The aggregation level: 'days', 'weeks', or 'months'.
 * @returns An array of AggregatedRecord objects with aggregated data.
 */
export const aggregateData = (
  data: BQPoint[],
  viewBy: ViewBy
): AggregatedData[] => {
  const sortedData = sortDataByDate(data);

  switch (viewBy) {
    case 'days':
      return aggregateByDays(sortedData, viewBy);
    case 'weeks':
      return aggregateByWeeks(sortedData, viewBy);
    default:
      return aggregateByMonths(sortedData);
  }
};
