import { TimeFrame } from '@msl/analytics-page-gateway-sdk';
import { ViewBy } from '../types/analytics';

/**
 * The dates from which to start the query, based on the given time frame.
 * format: YYYY-MM-DD
 */
export type RequestedDates = {
  dateFrom: string;
  dateTo: string;
};

export const formatNumber = (num: number): string => {
  if (num < 1000) {
    return num.toString();
  } else if (num < 999000) {
    return Math.round(num / 100) / 10 + 'K';
  } else {
    return (num / 1000000).toFixed(1) + 'M';
  }
};

// Format as mm/dd/yy
export const formatDate = (date: Date) => {
  const mm = (date.getMonth() + 1).toString().padStart(2, '0');
  const dd = date.getDate().toString().padStart(2, '0');
  const yy = date.getFullYear().toString().slice(-2);
  return `${mm}/${dd}/${yy}`;
};

export const calcPercentageDifference = (a: number, b: number): number => {
  if (b === 0) {
    return a === 0 ? 0 : 100; // Handle division by zero
  }
  return Math.round(((a - b) / b) * 100);
};

// Get the previous period date range based on the requested dates
export const getPreviousPeriodDateRange = (
  dates: RequestedDates
): { previousDateStart: string; previousDateEnd: string } => {
  const startDate = new Date(dates.dateFrom);
  const endDate = new Date(dates.dateTo);

  // Calculate total days
  const totalDays =
    (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24) + 1;

  // Split into two even halves
  const halfDays = totalDays / 2;

  const previousDateStart = startDate;
  const previousDateEnd = new Date(startDate);
  previousDateEnd.setDate(startDate.getDate() + halfDays - 1);

  const currentDateStart = new Date(previousDateEnd);
  currentDateStart.setDate(previousDateEnd.getDate() + 1);

  return {
    previousDateStart: formatDate(previousDateStart),
    previousDateEnd: formatDate(previousDateEnd),
  };
};

export const updateViewByFromTimeFrame = (timeFrame: TimeFrame): ViewBy => {
  switch (timeFrame) {
    case TimeFrame.TWO_WEEKS:
      return ViewBy.Days;
    case TimeFrame.MONTH:
      return ViewBy.Weeks;
    default:
      return ViewBy.Months;
  }
};
