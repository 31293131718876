//
import { AggregatedData } from './aggregate-data';
import { CSV_FILENAME } from './consts';

export function exportToCSV(
  data: AggregatedData[] | undefined,
  filename = CSV_FILENAME
): void {
  if (!data || !data.length) {
    console.error('No data provided to export.');
    return;
  }

  // 1) Define the columns in the final CSV:
  const headers = ['Date', 'Views'];
  const columns: [string, keyof AggregatedData][] = [
    ['Date', 'fullDate'],
    ['Views', 'views'],
  ];

  // 2) Build the CSV rows
  const csvRows: string[] = [];

  // Add the header row
  csvRows.push(headers.join(','));

  // For each data item, pick out `fullDate` and `views`, convert to strings, escape as needed
  data.forEach((item) => {
    // For each column in `columns`, grab the correct property from `item`
    const values = columns.map(([_, key]) => {
      let cell = String(item[key as keyof AggregatedData]); // convert number or string to string

      // Escape quotes by doubling them
      cell = cell.replace(/"/g, '""');

      // Wrap the cell in quotes if it contains commas, quotes, or newlines
      if (cell.search(/("|,|\n)/g) >= 0) {
        cell = `"${cell}"`;
      }
      return cell;
    });
    csvRows.push(values.join(','));
  });

  // 3) Combine into a single CSV string
  const csvContent = csvRows.join('\n');

  // 4) Prepend a UTF-8 BOM for better compatibility with Excel
  const csvContentWithBOM = '\uFEFF' + csvContent;

  // 5) Convert to Blob, create hidden link, and trigger a download
  const blob = new Blob([csvContentWithBOM], {
    type: 'text/csv;charset=utf-8;',
  });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
