<script setup lang="ts">
import { WsTooltip } from '@mfl/common-components';
import { strings } from '../analytics-page-strings';
import {
  formatNumber,
  getPreviousPeriodDateRange,
  type RequestedDates,
} from '../utils/analytics-helpers';
import { computed } from 'vue';

const props = defineProps<{
  isLoading: boolean;
  currentExposure: number;
  previousExposure: number;
  percentageDifference: number;
  percentageDifferenceClass: string;
  arrowIconClass: Record<string, boolean>;
  requestedDates: RequestedDates;
  hasChartData: boolean;
}>();

const prevDateRange = computed(() => {
  // If no dates are provided, use the last 2 weeks as the default
  if (
    props.requestedDates.dateFrom === '' &&
    props.requestedDates.dateTo === ''
  ) {
    const today = new Date();
    const twoWeeksAgo = new Date(today);
    twoWeeksAgo.setDate(today.getDate() - 27);

    return getPreviousPeriodDateRange({
      dateFrom: twoWeeksAgo.toISOString().split('T')[0],
      dateTo: today.toISOString().split('T')[0],
    });
  } else {
    return getPreviousPeriodDateRange(props.requestedDates);
  }
});
</script>

<template>
  <div class="total-exposure-container">
    <div class="total-exposure-label">
      <div>
        <span class="text-lg font-bold">{{ strings.exposureTitle }}</span>
      </div>
      <div>
        <span class="fa-regular fa-circle-info info-icon"></span>
        <WsTooltip
          size="sm"
          max-width="400px"
          aid="ANALYTICS_PAGE_TOTAL_EXPOSURE_TOOLTIP"
        >
          {{ strings.totalExposureTooltip }}
        </WsTooltip>
      </div>
    </div>

    <div>
      <span class="text-2xl font-bold">
        {{ !isLoading ? formatNumber(currentExposure) : '' }}
      </span>
    </div>

    <div v-if="!isLoading" class="current-vs-prevues-data">
      <div v-if="hasChartData" class="exposure-rate">
        <div class="exposure-badge" :class="percentageDifferenceClass">
          <i :class="[arrowIconClass, percentageDifferenceClass]"></i>
        </div>
        <div>
          <span class="text-xl font-bold" :class="percentageDifferenceClass">
            {{
              percentageDifference > 0
                ? '+' + percentageDifference
                : percentageDifference
            }}%
          </span>
        </div>
      </div>

      <div class="text-me">
        <span>{{ strings.exposureRange }}</span>
        <br />
        <span>
          {{ prevDateRange.previousDateStart }}
          -
          {{ prevDateRange.previousDateEnd }} :
          <b>{{ formatNumber(previousExposure) }}</b>
        </span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.total-exposure-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;

  .current-vs-prevues-data {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

.total-exposure-label {
  line-height: 0px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.info-icon {
  font-size: 16px;
  color: rgb(var(--color-gray-400));
}

.exposure-rate {
  display: flex;
  align-items: center;
  gap: 10px;

  :deep(.positive) {
    color: var(--Primary, #1e90fa);
  }

  :deep(.negative) {
    color: var(--Danger, #f21616);
  }

  :deep(.neutral) {
    color: var(--Gray-600, #2d2e30);
  }
}

.exposure-badge {
  display: flex;
  width: 30px;
  height: 30px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;

  &.positive {
    background: var(--Primary-300, #e1ebff);
  }

  &.negative {
    background: var(--Danger-50, #fff4f4);
  }

  &.neutral {
    display: none;
  }
}
</style>
