<script setup lang="ts">
import { onMounted, computed, reactive } from 'vue';
import { auth4Sdks } from '@mfl/platform-shell';
import {
  BQPoint,
  TimeFrame,
  analyticsPageGateway,
} from '@msl/analytics-page-gateway-sdk';
import { aggregateData, AggregatedData } from './utils/aggregate-data';
import { exportToCSV } from './utils/csv-export';
import {
  updateViewByFromTimeFrame,
  calcPercentageDifference,
  type RequestedDates,
} from './utils/analytics-helpers';
import { ViewBy } from './types/analytics';

import AnalyticsHeader from './components/analytics-header.vue';
import TimeFrameSelector from './components/time-frame-selector.vue';
import ExposureMetrics from './components/exposure-metrics.vue';
import ChartContainer from './components/chart-container.vue';
import AnalyticsBanner from './components/google-analytics-banner.vue';
import { toast, ToastStatus } from '@mfl/common-components';
import { strings } from './analytics-page-strings';
import { TERM_CURRENT, TERM_PREVIOUS } from './utils/consts';

// Group related state
const uiState = reactive({
  isLoading: true,
  selectedTimeFrame: TimeFrame.TWO_WEEKS,
  selectedViewBy: ViewBy.Days,
  isCsvDownload: false,
});

const chartData = reactive({
  current: [] as BQPoint[],
  previous: [] as BQPoint[],
  aggregatedData: [] as AggregatedData[],
  // dataToCsvExport: null as AggregatedData[] | null,
});

const metrics = reactive({
  totalCurrentExposure: 0,
  totalPreviousExposure: 0,
});

const requestedDates = reactive<RequestedDates>({
  dateFrom: '',
  dateTo: '',
});

// Computed properties
const percentageDifference = computed(() =>
  calcPercentageDifference(
    metrics.totalCurrentExposure,
    metrics.totalPreviousExposure
  )
);

const percentageDifferenceClass = computed(() => {
  if (percentageDifference.value > 0) return 'positive';
  if (percentageDifference.value < 0) return 'negative';
  return 'neutral';
});

const arrowIconClass = computed(() => ({
  'fa-regular': true,
  'fa-arrow-up': percentageDifference.value > 0,
  'fa-arrow-down': percentageDifference.value < 0,
}));

// Data fetching
const fetchChartData = async () => {
  uiState.isLoading = true;

  try {
    const { chart, totalCurrentPeriod, totalPreviousPeriod, dateFrom, dateTo } =
      await analyticsPageGateway.getAnalyticsCharts({
        timeFrame: uiState.selectedTimeFrame,
      });

    // Set chart data
    chartData.current =
      chart?.points?.filter((p) => p.term === TERM_CURRENT) || [];
    chartData.previous =
      chart?.points?.filter((p) => p.term === TERM_PREVIOUS) || [];
    chartData.aggregatedData = aggregateData(
      chartData.current,
      uiState.selectedViewBy
    );

    // Set metrics
    metrics.totalCurrentExposure = totalCurrentPeriod || 0;
    metrics.totalPreviousExposure = totalPreviousPeriod || 0;

    // Set RequestedDates
    requestedDates.dateFrom = dateFrom ? dateFrom : '';
    requestedDates.dateTo = dateTo ? dateTo : '';

    //Set Ui State
    uiState.isCsvDownload = chartData.current.length > 0 ? true : false;
  } catch {
    toast({
      status: ToastStatus.Error,
      aid: 'ANALYTICS_PAGE_FETCH_CHART_DATA_ERROR',
      message: strings.fetchErrorMessage,
    });
  } finally {
    uiState.isLoading = false;
  }
};

// Event handlers
const handleChartDataUpdate = (newChartData: AggregatedData[]) => {
  chartData.aggregatedData = newChartData;
};

const handleExportClick = () => {
  if (chartData.aggregatedData) {
    exportToCSV(chartData.aggregatedData);
  }
};

const handleTimeFrameChange = async (newTimeFrame: TimeFrame) => {
  uiState.selectedTimeFrame = newTimeFrame;
  uiState.selectedViewBy = updateViewByFromTimeFrame(newTimeFrame);
  await fetchChartData();
};

const handleViewByChange = async (newViewBy: ViewBy) => {
  uiState.selectedViewBy = newViewBy;
  chartData.aggregatedData = aggregateData(chartData.current, newViewBy);
};

// Lifecycle hooks
onMounted(async () => {
  await analyticsPageGateway.init(auth4Sdks());
  await fetchChartData();
});
</script>

<template>
  <div class="analytics-page-container">
    <AnalyticsHeader
      :is-csv-download="uiState.isCsvDownload"
      @export="handleExportClick"
    />

    <TimeFrameSelector
      :selected-time-frame="uiState.selectedTimeFrame"
      @update:time-frame="handleTimeFrameChange"
    />

    <div class="analytics-data-container drop-shadow">
      <ExposureMetrics
        :is-loading="uiState.isLoading"
        :current-exposure="metrics.totalCurrentExposure"
        :previous-exposure="metrics.totalPreviousExposure"
        :percentage-difference="percentageDifference"
        :percentage-difference-class="percentageDifferenceClass"
        :arrow-icon-class="arrowIconClass"
        :requested-dates="requestedDates"
        :has-chart-data="chartData.current.length > 0"
      />

      <ChartContainer
        :is-loading="uiState.isLoading"
        :has-data="chartData.current.length > 0"
        :selected-view-by="uiState.selectedViewBy"
        :selected-time-frame="uiState.selectedTimeFrame"
        :chart-data="chartData.aggregatedData"
        @update:view-by="handleViewByChange"
        @update:chart-data="handleChartDataUpdate"
      />
    </div>

    <AnalyticsBanner />
  </div>
</template>

<style scoped lang="scss">
.analytics-page-container {
  display: flex;
  padding: 24px 24px 0px 24px;
  flex-direction: column;
  gap: 24px;
  align-self: stretch;
  height: 100%;
}

.analytics-data-container {
  height: 100%;
  max-height: 450px;
  display: grid;
  grid-template-columns: 200px 3.5fr;
  gap: 100px;
  padding: 24px;
  border-radius: 8px;
  background: #fff;
}

.drop-shadow {
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
}
</style>
