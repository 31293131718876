<script setup lang="ts">
import { WsSelect, WsSelectOption } from '@mfl/common-components';
import { TimeFrame } from '@msl/analytics-page-gateway-sdk';
import { strings } from '../analytics-page-strings';

defineProps<{
  selectedTimeFrame: TimeFrame;
}>();

const emit = defineEmits<{
  (e: 'update:timeFrame', value: TimeFrame): void;
}>();

const TIME_FRAME_LABELS: Record<TimeFrame, string> = {
  [TimeFrame.TWO_WEEKS]: strings.timeFrameSelectLabelTwoWeeks,
  [TimeFrame.MONTH]: strings.timeFrameSelectLabelMonth,
  [TimeFrame.HALF_YEAR]: strings.timeFrameSelectLabelHalfYear,
  [TimeFrame.YEAR]: strings.timeFrameSelectLabelYear,
  [TimeFrame.TWO_YEARS]: strings.timeFrameSelectLabelTwoYears,
};

// Define options with proper type casting for values
const timeFrameSelectOptions = [
  {
    label: getTimeFrameLabel(TimeFrame.TWO_WEEKS),
    value: TimeFrame.TWO_WEEKS,
    aid: 'TIME_FRAME_SELECT_TWO_WEEKS',
  },
  {
    label: getTimeFrameLabel(TimeFrame.MONTH),
    value: TimeFrame.MONTH,
    aid: 'TIME_FRAME_SELECT_MONTH',
  },
  {
    label: getTimeFrameLabel(TimeFrame.HALF_YEAR),
    value: TimeFrame.HALF_YEAR,
    aid: 'TIME_FRAME_SELECT_HALF_YEAR',
  },
  {
    label: getTimeFrameLabel(TimeFrame.YEAR),
    value: TimeFrame.YEAR,
    aid: 'TIME_FRAME_SELECT_YEAR',
  },
  {
    label: getTimeFrameLabel(TimeFrame.TWO_YEARS),
    value: TimeFrame.TWO_YEARS,
    aid: 'TIME_FRAME_SELECT_TWO_YEARS',
  },
];

function getTimeFrameLabel(timeFrame: TimeFrame): string {
  return TIME_FRAME_LABELS[timeFrame] || TIME_FRAME_LABELS[TimeFrame.TWO_WEEKS];
}
</script>

<template>
  <div class="time-frame-select-container">
    <WsSelect
      :model-value="selectedTimeFrame"
      class="time-frame-select"
      :label="strings.timeFrameSelectLabel"
      label-position="outside"
      aid="ANALYTICS_PAGE_TIME_FRAME_SELECT"
      variant="outlined"
      size="md"
      :option-key="(v: TimeFrame) => v"
      :option-label="(v: TimeFrame) => getTimeFrameLabel(v)"
      @update:model-value="(val) => emit('update:timeFrame', val as TimeFrame)"
    >
      <WsSelectOption
        v-for="o in timeFrameSelectOptions"
        :key="o.value"
        :aid="o.aid"
        :value="o.value"
        :label="o.label"
      >
        {{ o.label }}
      </WsSelectOption>
    </WsSelect>
  </div>
</template>

<style scoped lang="scss">
.time-frame-select-container {
  width: 240px;
}
</style>
