/// Helper array for month names.
export const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

/// Helper array for day names.
export const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const CSV_FILENAME = 'wisestamp-signatures-views.csv';

export const TERM_CURRENT = 'current';
export const TERM_PREVIOUS = 'pre';
