<script setup lang="ts">
import { WsButton, WsTooltip } from '@mfl/common-components';
import { strings } from '../analytics-page-strings';

defineProps<{
  isCsvDownload: boolean;
}>();

const emit = defineEmits<{
  (e: 'export'): void;
}>();
</script>

<template>
  <div class="header">
    <span class="text-2xl font-bold">{{ strings.header }}</span>
    <WsButton
      aid="ANALYTICS_PAGE_EXPORT_CSV"
      :disabled="!isCsvDownload"
      type="button"
      color="primary"
      size="sm"
      @click="emit('export')"
    >
      {{ strings.buttonExportToCsvLabel }}
      <WsTooltip
        v-if="!isCsvDownload"
        size="sm"
        aid="ANALYTICS_PAGE_EXPORT_CSV_TOOLTIP"
      >
        {{ strings.buttonExportCsvDisabledTooltip }}
      </WsTooltip>
    </WsButton>
  </div>
</template>

<style scoped lang="scss">
.header {
  display: flex;
  justify-content: space-between;
}
</style>
